/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../../utils";
import "./newHeader.scss";
import Search from "../../../assets/svg/search";
import Basket from "../../../assets/svg/basket";
import { useDispatch, useSelector } from "react-redux";
import { useDetectOutsideClick } from "../../../Hooks/useDetectOutsideClick";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { searchAction } from "../../../actions/home";
import { doLogout } from "../../../actions/auth";
import Approve from "../../../assets/svg/approve";
import { toast } from "react-hot-toast";
import { WORDPRESS_BASE } from "../../../constants/api";

const NewHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mediaURL } = useSelector((state) => state.metaReducer);

  const { searchItemsList } = useSelector((state) => state.home);
  const basketCount = useSelector((state) => state.metaReducer?.basketCount);
  const { notificationCount } = useSelector((state) => state.metaReducer);

  const { userDetails } = useSelector((state) => state.user);
  const user = JSON.parse(localStorage.getItem("webUser"));
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;
  const profilePic = JSON.parse(localStorage.getItem("profilePic"));
  const userName = JSON.parse(localStorage.getItem("userName"));

  const headerRef = useRef(null);
  const searchRef = useRef(null);

  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [isSearchbarShow, setIsSearchbarShow] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [searchItems, setSearchItems] = useState([]);
  const [searchTexts, setSearchTexts] = useState("");

  useEffect(() => {
    const itms = searchItemsList.map((itm) => {
      if (itm.type === "Nonprofit") {
        return {
          ...itm,
          name: `${itm.name} ${itm?.taxId ? itm?.taxId : ""}`,
          updatedName: (
            <div className="searchItm">
              <div>{`${itm.name} (Nonprofit)`}</div>{" "}
              {itm.isNavigator === false && (
                <figure className="charityRight">
                  <Approve />
                </figure>
              )}
            </div>
          ),
        };
      }
      return {
        ...itm,
        name: `${itm.name} (Cause)`,
        updatedName: <div>{`${itm.name} (Cause)`}</div>,
      };
    });
    setSearchItems(itms);
  }, [searchItemsList]);

  useEffect(() => {
    if (searchTexts.length >= 3 || searchTexts.length === 0) {
      dispatch(
        searchAction({
          search: searchTexts.replace("-", ""),
          isNavigator: true,
        })
      );
    }
  }, [searchTexts]);

  const handleToggle = () => {
    setIsActive((prev) => !prev);
  };

  useDetectOutsideClick(searchRef, () => {
    setSearchIsOpen(false);
  });

  useDetectOutsideClick(headerRef, () => {
    setIsActive(false);
  });

  const handleOnSelect = (item) => {
    if (item.type === "Nonprofit") navigate(`/charity_details/${item.id}`);
    else navigate(`/cause_details/${item.id}`);
  };

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    setSearchTexts(string.replace(/[^\w]/g, ""));
  };

  const logoutHandler = () => {
    dispatch(doLogout()).then((res) => {
      toast.success(res?.message);
      navigate("/signin");
      window.location.reload();
    });
  };

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>
          {item.updatedName}
        </span>
      </>
    );
  };

  return (
    <header className="header">
      <div ref={headerRef} className="container full-container">
        <div className="header-wrap d-flex justify-content-between ">
          <div className="logo">
            <a href={WORDPRESS_BASE + "/"}>
              {/* <img src={toAbsoluteUrl("/images/logo.svg")} alt="logo" /> */}
              <img
                src={toAbsoluteUrl("/images/without_tagline_logo.svg")}
                alt="logo"
              />
            </a>
          </div>
          <div className="nav d-flex align-items-center justify-content-between">
            <div className="navigation d-flex">
              <ul className={`header-menu ${!isActive ? "" : "open-menu"} `}>
                <li onClick={() => setIsActive(false)}>
                  <a activeclassname="active" href={WORDPRESS_BASE + "/"}>
                    Home
                  </a>
                </li>

                <li>
                  <span className="headerDropdown">
                    <Link
                      className="dropdownLink"
                      to={"/about"}
                      exact="true"
                      activeclassname="active"
                    >
                      About
                    </Link>
                    <span className={"more"}>
                      <img
                        src={toAbsoluteUrl("/images/dropdown.svg")}
                        alt="dropdown"
                      />
                    </span>
                    <ul className="dropdown">
                      <li>
                        <a
                          href={WORDPRESS_BASE + "/founder/"}
                          activeclassname="active"
                        >
                          Founder’s Journey
                        </a>
                      </li>
                    </ul>
                  </span>
                </li>

                <li onClick={() => setIsActive(false)}>
                  <a
                    activeclassname="active"
                    href={WORDPRESS_BASE + "/rosi-givers/"}
                  >
                    Rosi Givers
                  </a>
                </li>

                <li>
                  <span className="headerDropdown">
                    <a
                      className="dropdownLink"
                      activeclassname="active"
                      href={WORDPRESS_BASE + "/nonprofits/"}
                    >
                      Nonprofits
                    </a>

                    <span className={"more"}>
                      <img
                        src={toAbsoluteUrl("/images/dropdown.svg")}
                        alt="dropdown"
                      />
                    </span>
                    <ul className="dropdown">
                      <li>
                        <a
                          activeclassname="active"
                          href={WORDPRESS_BASE + "/causes-overview/"}
                        >
                          Causes
                        </a>
                      </li>
                      <li>
                        <a
                          activeclassname="active"
                          href={WORDPRESS_BASE + "/communities/"}
                        >
                          Communities
                        </a>
                      </li>
                      <li>
                        <a
                          activeclassname="active"
                          href={WORDPRESS_BASE + "/events/"}
                        >
                          Events
                        </a>
                      </li>
                    </ul>
                  </span>
                </li>

                <li onClick={() => setIsActive(false)}>
                  <a
                    activeclassname="active"
                    href={WORDPRESS_BASE + "/partners/"}
                  >
                    Giving Partners
                  </a>
                </li>

                {user?.data?.authToken && (
                  <li onClick={() => setIsActive(false)}>
                    <NavLink exact="true" activeclassname="active" to="/post">
                      Chat & Feed
                    </NavLink>
                  </li>
                )}

                {userDetails?.userType === 2 && (
                  <li onClick={() => setIsActive(false)}>
                    <NavLink to="/Giving_Partner_Details">Partners</NavLink>
                  </li>
                )}

                <li>
                  <span className="headerDropdown">
                    <a
                      className="dropdownLink"
                      activeclassname="active"
                      href={WORDPRESS_BASE + "/support/"}
                    >
                      Support
                    </a>
                    <span className={"more"}>
                      <img
                        src={toAbsoluteUrl("/images/dropdown.svg")}
                        alt="dropdown"
                      />
                    </span>
                    <ul className="dropdown">
                      <li>
                        <a
                          activeclassname="active"
                          href={WORDPRESS_BASE + "/contact-us/"}
                        >
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </span>
                </li>

                <li className="registerItem">
                  <Link to="/registration" onClick={handleToggle}>
                    Sign Up
                  </Link>
                </li>
              </ul>
              <div
                className={`toggle-area ${!isActive ? "" : "close-btn1"}`}
                onClick={handleToggle}
              >
                <ul className="toggle">
                  <li className="top"></li>
                  <li className="middle"></li>
                  <li className="bottom"></li>
                </ul>
              </div>
            </div>
            <div className="navigation-right">
              {/* with out login */}
              {!userId || user?.data?.userType === 3 ? (
                <ul className="withoutLoginList">
                  <li className="logOutSearch search">
                    <div
                      ref={searchRef}
                      className={searchIsOpen ? "autoSearch" : "searchInput"}
                    >
                      <ReactSearchAutocomplete
                        items={searchItems}
                        onSelect={handleOnSelect}
                        onSearch={handleOnSearch}
                        autoFocus={true}
                        formatResult={formatResult}
                        className="headerSearch"
                        placeholder="Search Nonprofit, Cause & Tax ID/EIN"
                      />
                    </div>
                    <a
                      href="true"
                      className="demo"
                      onClick={(e) => {
                        e.preventDefault();
                        setSearchIsOpen((prev) => !prev);
                        setIsSearchbarShow(true);
                      }}
                    >
                      <Search />
                    </a>
                  </li>
                  {user?.data?.userType === 3 ? (
                    <li>
                      <span className="guestName">
                        {`Hello Guest, ${user?.data?.firstName} ${user?.data?.lastName}`}
                      </span>
                    </li>
                  ) : (
                    <li>
                      <Link to="/registration" className="register">
                        Sign Up
                      </Link>
                    </li>
                  )}
                  <li>
                    {userId && (
                      <Link
                        className="basketCount"
                        to={
                          userId || user?.data?.userType !== 3
                            ? "/basket"
                            : "/charity"
                        }
                      >
                        <Basket />
                        {!basketCount ? "" : <span>{basketCount}</span>}
                      </Link>
                    )}
                  </li>
                </ul>
              ) : (
                <ul className="logginList">
                  <li className="loginSearch search">
                    {isSearchbarShow && (
                      <div
                        ref={searchRef}
                        className={searchIsOpen ? "autoSearch" : "searchInput"}
                      >
                        <ReactSearchAutocomplete
                          items={searchItems}
                          onSelect={handleOnSelect}
                          onSearch={handleOnSearch}
                          autoFocus
                          formatResult={formatResult}
                          className="headerSearch"
                          placeholder="Search Nonprofit, Cause & Tax ID/EIN"
                        />
                      </div>
                    )}
                    <a
                      href="true"
                      className="demo"
                      to=""
                      onClick={(e) => {
                        e.preventDefault();
                        setSearchIsOpen((prev) => !prev);
                        setIsSearchbarShow(true);
                      }}
                    >
                      <Search />
                    </a>
                  </li>
                  <li>
                    <Link to="/chat">
                      <div className="message">
                        <img
                          src={toAbsoluteUrl("/images/message.svg")}
                          alt="message"
                        />
                        {/* <span></span> */}
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/notification">
                      <div className="bell">
                        <img
                          src={toAbsoluteUrl("/images/bell.svg")}
                          alt="bell"
                        />
                        {!notificationCount ? "" : <span></span>}
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/profile_details"
                      state={{ userID: user?.data?._id }}
                      className="userHeader"
                    >
                      <figure className="userProfile">
                        {profilePic === "null" || mediaURL === undefined ? (
                          <img
                            src={toAbsoluteUrl("/images/placeholder.png")}
                            alt="placeholder"
                          />
                        ) : (
                          <img
                            src={`${mediaURL}userProfilePic/${profilePic}`}
                            alt="user"
                            // onError="this.src='fallback-img.jpg'"
                          />
                        )}
                      </figure>
                      <h4>{userName}</h4>
                    </Link>
                  </li>
                  <li>
                    <button className="exitBtn" onClick={logoutHandler}>
                      <img
                        src={toAbsoluteUrl("/images/exit.png")}
                        alt="logout"
                      />
                      {/* <div className="Logout">Logout</div> */}
                    </button>
                  </li>
                  <li>
                    {userId && (
                      <Link
                        className="basketCount"
                        to={userId ? "/basket" : "/charity"}
                      >
                        <Basket />
                        {basketCount ? <span>{basketCount}</span> : ""}
                      </Link>
                    )}
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default NewHeader;
