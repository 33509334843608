import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import React from "react";
import { Autoplay, Pagination } from "swiper";
import "./swiper.scss";
import { toAbsoluteUrl } from "../../../utils";

function swiper() {
  const pagination = {
    clickable: true,
  };

  const images = [
    {
      id: 1,
      image: "/images/girInRainbowSkirt.jpg",
      alt: "swiper one",
    },
    {
      id: 2,
      image: "/images/getRosiApp.jpg",
      alt: "swiper two",
    },
    {
      id: 3,
      image: "/images/fivePeople.jpg",
      alt: "swiper three",
    },
    {
      id: 4,
      image: "/images/quote.jpg",
      alt: "swiper four",
    },
    // {
    //   id: 1,
    //   image: "/images/sign-one.png",
    //   alt: "swiper one",
    // },
    // {
    //   id: 2,
    //   image: "/images/sign-two.png",
    //   alt: "swiper two",
    // },
    // {
    //   id: 3,
    //   image: "/images/sign-three.png",
    //   alt: "swiper three",
    // },
  ];

  return (
    <Swiper
      loop={true}
      pagination={pagination}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, Pagination]}
      className="main-swiper"
    >
      {images.map((image) => (
        <SwiperSlide key={image?.id}>
          <figure>
            <img src={toAbsoluteUrl(image.image)} alt={image.alt} />
          </figure>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default swiper;
