import React from "react";
import "./swiperText.scss";

function SwiperText() {
  return (
    <>
      <div className="swiperText">
        <h3>Meaningful Giving Made Easy</h3>
        {/* <p>
          Add the Charities And Causes You Want To Support, Track All Your
          Donations
        </p> */}
      </div>
    </>
  );
}

export default SwiperText;
