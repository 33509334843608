export const { REACT_APP_API_BASE: API_BASE } = process.env;
export const { REACT_APP_WORDPRESS_BASE: WORDPRESS_BASE } = process.env;

export const API_LOGIN = "/v1/users/login";

export const API_LOGOUT = "/v1/common/commonLogOut";

export const API_DELETE_ACCOUNT = "/v1/users/delete/";

export const API_META = "/auth/metaData";

export const API_REGISTRATION = "/v1/users/create";

export const API_VERIFY_OTP = "/v1/users/verify-otp";

export const API_RESEND_OTP = "/v1/users/resend-otp";

export const API_VERIFY_EMAIL = "/v1/users/sentMailToUser";

export const API_FORGOT_PASS = "/v1/users/forgotPassword";

export const API_CHANGE_PASS = "/v1/users/changePassword";

export const API_RESET_PASS = "/v1/users/resetPassword";

export const API_USER_DETAILS = "/v1/users/getUserDetails";

export const API_UPDATE_USER_DETAILS = "/v1/users/updateUserDetails";

export const API_CHARITY_LIST = "/v1/charity/charityFilter";

export const API_CHARITY_DETAILS = "/v1/charity/charityDetails";

export const API_CAUSE_LIST = "/v1/cause/causesList";

export const API_CAUSE_DETAILS = "/v1/cause/causesDetails";

export const API_HOMEPAGE_DETAILS = "/v1/homepage/dynamicData";

export const API_ABOUTPAGE_DETAILS = "/v1/aboutUs/aboutUsList";

export const API_IMAGE_UPLOAD = "/local-image/uploadImage";
export const API_IMAGE_DELETE = "/local-image/removeImage";

export const API_VIDEO_UPLOAD = "/local-image/uploadVideo";
export const API_VIDEO_DELETE = "/local-image/removeVideo";

export const API_REFRESH_TOKEN = "/v1/common/refreshToken";

export const API_BADGE_LIST = "/v1/users/badgesList/";
export const API_DONATION_GOAL = "/v1/users/addUserGoal";
export const API_UPLOAD_USER_PROFILE = "/v1/users/uploadUserImage";

// charity related api
export const API_CHARITY_COMMUNITY_LIST = "/v1/charity/charityCommunityDetails";

//cause related api
export const API_CAUSE_COMMUNITY_LIST = "/v1/cause/causeCommunityDetails";

// charity registration related api
export const API_CATEGORY = "/admin/charity-category/getAll";
export const API_CAUSE = "/admin/cause/getAll";
export const API_CHARITY_REGISTRATION = "/admin/charity/create";

export const API_COUNTRY = "/v1/common/getAllCountry";
export const API_STATE = "/v1/common/getAllState";
export const API_CITY = "/v1/common/getAllCities";

// communtiy api

export const API_ALL_COMMUNITY = "/v1/community/getAllCommunityList";
export const API_NOT_ASSO_COMMUNITY =
  "/v1/community/getAllCommunityListNotAssociated";
export const API_COMMUNITY_DETAILS = "/v1/community/getCommunityDetail/";
export const API_JOIN_COMMUNITY = "/v1/joinCommunity/add";
export const API_LEAVE_COMMUNITY = "/v1/joinCommunity/";
export const API_CREATE_COMMUNITY_RATING = "/v1/community-rating/create";
export const API_UPDATE_COMMUNITY_RATING = "/v1/community-rating/update/";
export const API_GET_COMMUNITY_MEMBERS = "/v1/community/memberList/";

// favour charity and list api

export const API_ADD_CHARITY_TO_FAV = "/v1/favoritelist/addCharity";
export const API_ADD_CAUSE_TO_FAV = "/v1/favoritelist/addCause";
export const API_REMOVE_CHARITY_OR_CAUSE_TO_FAV = "/v1/favoritelist/remove";
export const API_FAV_LIST = "/v1/favoritelist/list";

// event api

export const API_EVENT_LIST = "/v1/event/eventFilterByCommunity";
export const API_EVENT_DETAILS = "/v1/event/eventDetails";
export const API_JOIN_EVENT = "/v1/event/join";
export const API_UPDATE_EVENT = "/v1/event/updateStatus";
export const API_EVENT_LIST_OF_CAUSE = "/v1/event/causeEventList/";
export const API_EVENT_LIST_OF_CHARITY = "/v1/event/eventList/";
export const API_MY_EVENT_LIST = "/v1/event/userFavEventList";
export const API_ALL_UPCOMING_EVENT_LIST = "/v1/event/userAllUpcomingEventList";

// post module
export const API_COMMUNITY_POST_LIST = "/v1/posts/getAllCommunityPostList/";
export const API_CREATE_POST = "/v1/posts";
export const API_LIKE_UNLIKE_POST = "/v1/posts/likeUnlikePost/";
export const API_HIDE_POST = "/v1/posts/hidePost";
export const API_DELETE_POST = "/v1/posts/";
export const API_REPORT_POST = "/v1/posts/reportPost/";
export const API_POST_COMMENT = "/v1/posts/postComment";
export const API_RENDER_COMMENTS = "/v1/posts/getPostCommnetList/";
export const API_LIKE_UNLIKE_COMMENTS = "/v1/posts/likeUnlikeComment/";
export const API_SHARE_POST = "/v1/posts/sharePost/";
export const API_USER_PROFILE_DETAILS = "/v1/posts/userProfileFeedData/";
export const API_USER_POST_LIST = "/v1/posts/userFriendPostList/";
export const API_JOIN_COMMUNITY_POST = "/v1/posts/communityUserPost";
export const API_COMMENT_REPLY = "/v1/posts/postCommentRply";

// contect us
export const API_CONTACT_US = "/v1/ContactUs/create";

// friend post
export const API_POST_LIST = "/v1/posts/getAllDonorFriendsPostList/";

// friend module
export const API_SEND_FRIEND_REQUEST = "/v1/web-friend/sendFriendRequest";
export const API_PENDING_FRIEND_REQUEST = "/v1/web-friend/pendingFriendRequest";
export const API_ACCEPT_REJECT_REQUEST = "/v1/web-friend/acceptedAndRejected";
export const API_FRIEND_LIST = "/v1/web-friend/approvedFriendRequest";
export const API_GET_ALL_USER_LIST = "/v1/web-friend/findDonor";
export const API_MUTUAL_FRIEND_LIST = "/v1/web-friend/mutualFriendList";
export const API_UNFRIEND_FRIEND = "/v1/web-friend/unFriend";

//basket module
export const API_ADD_TO_BASKET = "/v1/basket/addToBasket";
export const API_BASKET_DETAILS = "/v1/basket/getBasketDetails";
export const API_REMOVE_ITEM_FROM_BASKET = "/v1/basket/removeBasket";
export const API_BASKET_COUNT = "/v1/basket/basketCount/";

//payment module
export const API_PAYMENT_WITH_UNSAVED_CARD =
  "/v1/payment/makePaymentWithUnSavedCard";
export const API_PAYMENT_WITH_SAVED_CARD =
  "/v1/payment/makePaymentWithSavedCard";
export const API_LIST_OF_ALL_CARD = "/v1/payment/listAllCards";
export const API_ADD_CARD = "/v1/payment/addToCard";
export const API_DELETE_CARD = "/v1/payment/deleteSavedCard";
export const API_TRANSECTION_LIST = "/v1/payment/transactionList";
export const API_CANCEL_SUBSCRIPTION = "/v1/payment/cancleSubscription";
export const API_TRANSECTION_RECIEPT = "/v1/payment/generateTransactionReciept";
export const API_ALL_TRANSACTION_PDF = "/v1/payment/allTransactionPdf";
export const API_UPDATE_SUBSCRIPTION = "/v1/payment/subscriptionUpdate/";
export const API_SUBSCRIPTION_DETAILS = "/v1/payment/subscriptionDetails/";
export const API_UPDATE_SUBSCRIPTION_ITEMS =
  "/v1/payment/getUpdateSubPercenatge/";
export const API_UPDATE_SUBSCRIPTION_PERCENTAGE =
  "/v1/payment/subPercentageUpdate";

// cms
export const API_CMS_DETIALS = "/v1/web-cms-pages/ViewCms/";

// faq
export const API_FAQ_CATEGORY_LIST =
  "/admin/admin-faq-category/faqCategoryList";
export const API_FAQ_LIST = "/admin/admin-faq/faqList";

// chat
export const API_CHAT_CREATE_ROOM = "/v1/chat/createRoom";
export const API_CHAT_NOTIFICATION_COUNT = "/v1/chat/getChatCount";

//Strategic-Employee
export const API_STRATEGIC_PARTNER_LIST =
  "/v1/strategic-partner/strategicPartnerList";
export const API_CREATE_EMPLOYEE = "/admin/employee/createEmployee";
export const API_STRATEGIC_DETAIL = "/v1/strategic-partner/get/";
export const API_STRATEGIC_MUTUAL_LIST =
  "/v1/strategic-partner/mutualFriendList";
export const API_STRATEGIC_MEMBER_LIST =
  "/v1/strategic-partner/strategicPartnerList";
export const API_EMPLOYEE_POST_LIST = "/v1/posts/employeePost/";
export const API_STRATEGIC_MUTUAL_FRIEND =
  "/v1/strategic-partner/mutualFriendList";
export const API_STRATEGIC_EVENT = "/v1/event/listStrategicPartner/";

//Searh API
export const API_GLOBAL_SEARCH = "/v1/homepage/commonSearch";

// giving partner
export const API_GIVING_PARTNER_DETAILS = "/v1/givingpartnerpage/details";

// Notifiction
export const API_NTIFICTION_DELETE = "/v1/notification/";
export const API_NOTIFICTION_LIST = "/v1/notification/list";
export const API_FETCHNOTIFICATION_LIST =
  "v1/notification/fetchNotificationDetails";

//Chat
export const API_CLEARE_CHAT_HISTORY = "/v1/chat/clearChatHistory/";
