import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import NewHeader from "../layout/newHeader";

const paths = ["/signin", "/registration", "/forgot_password"];

const LayoutForSignin = () => {
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("webUser"));

  useEffect(() => {
    if (user && paths.includes(window.location.pathname)) {
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <NewHeader />
      <div className="body-section">
        <Outlet />
      </div>
    </>
  );
};

export default LayoutForSignin;
